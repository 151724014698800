@import url("https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap");
@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0); }
  10% {
    -webkit-transform: scale(1.2, 0.8);
    transform: scale(1.2, 0.8); }
  30% {
    -webkit-transform: scale(0.8, 1.1) translateY(-5px);
    transform: scale(0.8, 1.1) translateY(-5px); }
  50% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }
@keyframes bounce {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0); }
  10% {
    -webkit-transform: scale(1.2, 0.8);
    transform: scale(1.2, 0.8); }
  30% {
    -webkit-transform: scale(0.8, 1.1) translateY(-5px);
    transform: scale(0.8, 1.1) translateY(-5px); }
  50% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes bounceNav {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0); }
  10% {
    -webkit-transform: scale(0.95, 1.05) translateY(0);
    transform: scale(0.95, 1.05) translateY(0); }
  20% {
    -webkit-transform: scale(0.85, 1.1) translateY(-5px) translateX(-5px);
    transform: scale(0.85, 1.1) translateY(-5px) translateX(-5px); }
  40% {
    -webkit-transform: scale(1.05, 0.95);
    transform: scale(1.05, 0.95); }
  60% {
    -webkit-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05); }
  100% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0); } }

@keyframes bounceNav {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0); }
  10% {
    -webkit-transform: scale(0.95, 1.05) translateY(0);
    transform: scale(0.95, 1.05) translateY(0); }
  20% {
    -webkit-transform: scale(0.85, 1.1) translateY(-5px) translateX(-5px);
    transform: scale(0.85, 1.1) translateY(-5px) translateX(-5px); }
  40% {
    -webkit-transform: scale(1.05, 0.95);
    transform: scale(1.05, 0.95); }
  60% {
    -webkit-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05); }
  100% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0); } }

@-webkit-keyframes bounceAnchor {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0); }
  10% {
    -webkit-transform: scale(0.95, 1.05) translateX(0);
    transform: scale(0.95, 1.05) translateX(0); }
  20% {
    -webkit-transform: scale(0.85, 1.1) translateY(-7px);
    transform: scale(0.85, 1.1) translateY(-7px); }
  40% {
    -webkit-transform: scale(1.05, 0.95);
    transform: scale(1.05, 0.95); }
  60% {
    -webkit-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05); }
  100% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0); } }

@keyframes bounceAnchor {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0); }
  10% {
    -webkit-transform: scale(0.95, 1.05) translateX(0);
    transform: scale(0.95, 1.05) translateX(0); }
  20% {
    -webkit-transform: scale(0.85, 1.1) translateY(-7px);
    transform: scale(0.85, 1.1) translateY(-7px); }
  40% {
    -webkit-transform: scale(1.05, 0.95);
    transform: scale(1.05, 0.95); }
  60% {
    -webkit-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05); }
  100% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0); } }

@-webkit-keyframes buttonPulseBlue {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(31, 50, 59, 0.5);
    box-shadow: 0 0 0 0 rgba(31, 50, 59, 0.5); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(31, 50, 59, 0);
    box-shadow: 0 0 0 10px rgba(31, 50, 59, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(31, 50, 59, 0);
    box-shadow: 0 0 0 0 rgba(31, 50, 59, 0); } }

@keyframes buttonPulseBlue {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(31, 50, 59, 0.5);
    box-shadow: 0 0 0 0 rgba(31, 50, 59, 0.5); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(31, 50, 59, 0);
    box-shadow: 0 0 0 10px rgba(31, 50, 59, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(31, 50, 59, 0);
    box-shadow: 0 0 0 0 rgba(31, 50, 59, 0); } }

@-webkit-keyframes buttonPulseGreen {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(31, 50, 59, 0.5);
    box-shadow: 0 0 0 0 rgba(31, 50, 59, 0.5); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(31, 50, 59, 0);
    box-shadow: 0 0 0 10px rgba(31, 50, 59, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(31, 50, 59, 0);
    box-shadow: 0 0 0 0 rgba(31, 50, 59, 0); } }

@keyframes buttonPulseGreen {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(31, 50, 59, 0.5);
    box-shadow: 0 0 0 0 rgba(31, 50, 59, 0.5); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(31, 50, 59, 0);
    box-shadow: 0 0 0 10px rgba(31, 50, 59, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(31, 50, 59, 0);
    box-shadow: 0 0 0 0 rgba(31, 50, 59, 0); } }

@-webkit-keyframes bounceModal {
  0% {
    -webkit-transform: scale(0.1) translateY(0);
    transform: scale(0.1) translateY(0); }
  10% {
    -webkit-transform: scale(0.5, 1.05) translateY(0);
    transform: scale(0.5, 1.05) translateY(0); }
  20% {
    -webkit-transform: scale(0.8, 1.1) translateY(-5px);
    transform: scale(0.8, 1.1) translateY(-5px); }
  40% {
    -webkit-transform: scale(1.05, 0.95);
    transform: scale(1.05, 0.95); }
  60% {
    -webkit-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05); }
  100% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0); } }

@keyframes bounceModal {
  0% {
    -webkit-transform: scale(0.1) translateY(0);
    transform: scale(0.1) translateY(0); }
  10% {
    -webkit-transform: scale(0.5, 1.05) translateY(0);
    transform: scale(0.5, 1.05) translateY(0); }
  20% {
    -webkit-transform: scale(0.8, 1.1) translateY(-5px);
    transform: scale(0.8, 1.1) translateY(-5px); }
  40% {
    -webkit-transform: scale(1.05, 0.95);
    transform: scale(1.05, 0.95); }
  60% {
    -webkit-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05); }
  100% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0); } }

body {
  font-family: "Montserrat", sans-serif;
  background-color: #0b1114;
  color: #0b1114; }

a {
  color: #9ce6b3;
  text-decoration: underline; }
  a:visited {
    color: #9ce6b3; }
  a:hover {
    cursor: pointer; }

.bounceAnchor {
  display: inline-block;
  text-align: left; }
  .bounceAnchor a {
    text-align: center; }
  .bounceAnchor:hover {
    -webkit-animation: bounceAnchor 1s ease;
    animation: bounceAnchor 1s ease; }
  .bounceAnchor:active {
    -webkit-animation: bounceAnchor 1s ease;
    animation: bounceAnchor 1s ease; }

header {
  text-align: left;
  height: 40px;
  position: fixed;
  background-color: #0b1114;
  width: 100vw;
  top: 0;
  padding-bottom: 5px;
  z-index: 10000; }
  header h1 {
    display: inline-block;
    font-size: 14pt; }
  header a {
    text-decoration: none;
    color: #FFF; }
    header a:visited {
      color: #FFF; }
  header img {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    width: 23px;
    height: 23px;
    background-color: #FFF;
    vertical-align: text-bottom; }

h1, h2, h3 {
  font-family: "Raleway", sans-serif;
  font-weight: thin;
  color: #0b1114; }

.App-header {
  margin-top: -2px;
  padding-bottom: 7px;
  color: #FFF; }
  .App-header h1 {
    color: #FFF;
    margin-right: 2px;
    margin-left: 5px; }

.mobileNavBtns {
  display: none;
  width: 0;
  height: 0; }

.mobileNavWrap {
  display: inline-block; }

nav {
  font-family: "Raleway", sans-serif;
  font-size: 1em;
  font-weight: lighter;
  display: inline-block; }
  nav ul li {
    display: inline;
    color: #FFF;
    padding: 0 50px 0 50px; }
  nav ul a {
    color: #FFF;
    text-decoration: none;
    padding-bottom: 0px;
    border-bottom: 1px transparent #60b585; }
    nav ul a:hover {
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
      -webkit-transition-timing-function: ease;
      -o-transition-timing-function: ease;
      transition-timing-function: ease;
      font-weight: bold;
      padding-bottom: 3px;
      border-bottom: 3px solid #60b585; }

main {
  margin-top: 40px;
  margin-left: 10vw;
  margin-right: 10vw;
  padding-bottom: 20em; }

.pageTitle {
  font-size: 3em; }

.App {
  position: relative;
  min-height: 100vh;
  overflow: hidden; }

button {
  padding: 15px 20px;
  width: 180px;
  font-size: 1em;
  text-transform: uppercase;
  color: #FFF;
  border-radius: 30px;
  border: #1f323b;
  background-color: #1f323b;
  -webkit-box-shadow: 0 0 0 0 #1f323b;
  box-shadow: 0 0 0 0 #1f323b; }
  button:hover {
    cursor: pointer;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    background-color: #02424e;
    -webkit-animation: buttonPulseBlue 2s infinite;
    animation: buttonPulseBlue 2s infinite; }

footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #FFF;
  text-align: center; }
  footer #logoftWrap {
    width: 90%;
    text-align: center;
    margin: 0 auto; }
    footer #logoftWrap img {
      display: inline-block; }
  footer .footerOval {
    background-image: -webkit-linear-gradient(320deg, #60b585, #1f323b);
    background-image: -o-linear-gradient(320deg, #60b585, #1f323b);
    background-image: linear-gradient(130deg, #60b585, #1f323b);
    height: 900px;
    width: 170%;
    background-color: #60b585;
    border-radius: 50%;
    position: absolute;
    bottom: -650px;
    left: 50%;
    margin-left: -85%;
    z-index: -100; }
  footer small {
    font-size: 1.5em;
    text-transform: uppercase;
    word-spacing: -.2em; }
  footer img {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    background-color: white;
    width: 22px;
    height: 22px;
    vertical-align: text-bottom; }
  footer span {
    font-size: 1em;
    vertical-align: top; }

.socialNav ul {
  padding-left: 0; }
  .socialNav ul li {
    list-style-type: none;
    display: inline-block; }
  .socialNav ul img {
    background-color: transparent;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }

.socialNav:hover {
  cursor: pointer; }

.socialNavTop {
  margin-top: -8px;
  display: inline-block;
  position: fixed;
  top: 0;
  right: 0; }
  .socialNavTop ul li img {
    width: 30px;
    height: 30px;
    padding: 0 10px; }

.socialNavFt {
  text-align: center; }
  .socialNavFt ul li img {
    padding: 0 15px; }

/**** home page ****/
#hero {
  margin-top: 30vh; }
  #hero #heroWrapper {
    text-align: right;
    display: inline-block;
    margin-right: .3em; }
    #hero #heroWrapper h3 {
      font-weight: 100;
      font-size: 1.3em; }
  #hero img {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    width: 100px;
    height: 100px;
    display: inline-block;
    vertical-align: text-bottom; }

.heroOval {
  height: 100vh;
  width: 170%;
  background-color: #60b585;
  border-radius: 50%;
  position: absolute;
  top: -20vh;
  right: -80vh;
  z-index: -100; }

.heroTitle {
  text-transform: uppercase;
  word-spacing: -.2em;
  font-weight: 100;
  margin-bottom: -.5em; }

#photos {
  margin-top: 35vh;
  margin-bottom: -690px; }
  #photos h3 {
    text-transform: lowercase;
    font-size: 3em;
    font-weight: lighter; }
  #photos #bgPlant {
    z-index: -101;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    position: absolute;
    top: 49vh;
    left: -90px;
    width: 800px; }
  #photos .pihBottom {
    text-align: right;
    font-size: 2em;
    font-family: "Montserrat", sans-serif; }
    #photos .pihBottom a {
      color: #02424e; }
      #photos .pihBottom a:hover {
        font-weight: bold; }
    #photos .pihBottom #hpHandle {
      width: 50px;
      vertical-align: middle;
      padding-right: 10px; }
  #photos #bgPlantBottom {
    z-index: -102;
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    position: relative;
    top: -450px;
    right: -280px;
    width: 800px; }

/*** donations ***/
.donations {
  width: 100%;
  background-image: -webkit-linear-gradient(320deg, #60b585, #1f323b);
  background-image: -o-linear-gradient(320deg, #60b585, #1f323b);
  background-image: linear-gradient(130deg, #60b585, #1f323b);
  padding: 20px;
  text-align: right; }
  .donations #donationInfo {
    background-color: #9ce6b3;
    padding: 20px;
    max-width: 500px;
    display: inline-block;
    text-align: center; }
    .donations #donationInfo button {
      display: inline-block; }
    .donations #donationInfo h2, .donations #donationInfo h3 {
      text-align: center;
      text-transform: uppercase; }
    .donations #donationInfo h2 {
      margin-bottom: -.5em; }
    .donations #donationInfo h3 {
      font-weight: 100;
      font-size: 1em;
      margin-bottom: 2em; }
    .donations #donationInfo p {
      text-align: left;
      padding: 0 30px;
      margin-bottom: 2em; }
    .donations #donationInfo a {
      color: #1c3627;
      font-weight: bold; }

/*** about, contact, booking ***/
.pageOval {
  height: 100vh;
  width: 170%;
  background-color: #60b585;
  border-radius: 50%;
  position: absolute;
  top: -70vh;
  right: 50%;
  margin-right: -85%;
  z-index: -100; }

.pageTitle {
  text-align: center;
  text-transform: uppercase;
  font-weight: 100;
  margin-top: 2em; }

.pageTitleMrg {
  margin-bottom: 3em; }

/*** about ***/
#aboutMe {
  float: right;
  width: 60%;
  max-width: 1000px;
  padding: 20px;
  background-color: #9ce6b3; }
  #aboutMe p {
    font-size: 11pt; }

#specialties {
  clear: both;
  color: #FFF;
  text-align: center;
  padding-top: 10em;
  margin-bottom: 10em; }
  #specialties ul li {
    list-style-type: none;
    display: inline-block;
    font-size: 1.2em;
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    margin: 0 5vw; }

.cta {
  width: 100%;
  padding: 20px;
  margin-bottom: -8em;
  margin-top: 15em; }
  .cta p {
    display: inline-block;
    width: 50%;
    max-width: 700px;
    font-size: 1.2em; }
    .cta p .ctaSpan {
      font-weight: bold;
      color: #02424e; }
  .cta button {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10vw;
    margin-bottom: 25px; }
  .cta .bgcta {
    z-index: -99999;
    width: 120%;
    position: relative;
    left: -20%;
    top: -170px; }

/*** booking page ***/
#bookingInfo {
  color: #FFF; }
  #bookingInfo h3, #bookingInfo h4 {
    font-size: 1.5em;
    line-height: .4em;
    text-transform: lowercase;
    font-weight: 100; }
  #bookingInfo ul li {
    line-height: 1.8em; }

#calendar {
  color: #FFF;
  font-family: "Montserrat", sans-serif; }
  #calendar #cal {
    width: 99%; }
    #calendar #cal #arrowWrap {
      width: 99%;
      margin: 0 auto;
      position: relative;
      top: 5em; }
      #calendar #cal #arrowWrap .calArrow {
        background-color: #60b585;
        width: 10em; }
        #calendar #cal #arrowWrap .calArrow:hover {
          -webkit-transition: 0.3s;
          -o-transition: 0.3s;
          transition: 0.3s;
          cursor: pointer;
          background-color: #1c3627; }
      #calendar #cal #arrowWrap #prev {
        margin-left: 20px; }
        #calendar #cal #arrowWrap #prev i {
          margin-right: 10px; }
      #calendar #cal #arrowWrap #next {
        float: right;
        margin-right: 20px; }
        #calendar #cal #arrowWrap #next i {
          color: #FFF;
          margin-left: 30px; }
    #calendar #cal table {
      width: 99%;
      margin: 0 auto; }
    #calendar #cal caption {
      width: 100%;
      border: 1px solid #9ce6b3;
      border-bottom: none;
      background-color: #02424e;
      font-size: 2em;
      text-transform: uppercase;
      font-family: "Raleway", sans-serif;
      padding: 20px 0; }
      #calendar #cal caption #calYear {
        font-family: "Montserrat", sans-serif; }
    #calendar #cal th {
      color: #0b1114;
      text-transform: lowercase;
      font-size: 1.5em;
      font-weight: 100;
      width: 8vw;
      height: 20px;
      background-color: #9ce6b3; }
    #calendar #cal td {
      color: #FFF;
      background-color: transparent;
      border: 1px solid #9ce6b3;
      height: 6vw;
      padding: 5px;
      vertical-align: top; }
    #calendar #cal .oldDate {
      color: grey;
      border: 1px solid #1f323b; }
      #calendar #cal .oldDate p {
        text-transform: uppercase; }
      #calendar #cal .oldDate:hover {
        cursor: cursor;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        background-color: #1f323b; }
    #calendar #cal .futureDate {
      color: #FFF;
      background-color: #60b585; }
      #calendar #cal .futureDate p {
        text-transform: uppercase; }
      #calendar #cal .futureDate:hover {
        cursor: pointer;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        background-color: #1c3627; }

#contactForQuestions {
  margin-top: 10em; }

/*** contact page ***/
.contactForm {
  margin-bottom: 10em; }
  .contactForm h3 {
    color: #FFF;
    text-transform: lowercase;
    font-weight: 100;
    font-size: 1.5em;
    font-family: "Montserrat", sans-serif; }
  .contactForm .cntError {
    display: none;
    font-size: 10pt;
    font-weight: bold;
    color: #FF3352; }
  .contactForm #rsnErr {
    margin-bottom: -15px;
    margin-left: 5px;
    margin-top: 5px; }
  .contactForm #contactReason {
    margin-left: 20px; }
    .contactForm #contactReason input[type=radio] {
      visibility: hidden; }
      .contactForm #contactReason input[type=radio]:checked + label {
        background-color: #9ce6b3;
        color: #0b1114; }
    .contactForm #contactReason .contactBtn {
      padding: 15px 20px;
      width: 180px;
      border: 3px solid #9ce6b3;
      border-radius: 30px;
      background-color: transparent;
      margin-right: 100px;
      text-align: center;
      font-size: 1em;
      text-transform: uppercase;
      color: #9ce6b3;
      display: inline-block; }
      .contactForm #contactReason .contactBtn:hover {
        cursor: pointer;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        background-color: #9ce6b3;
        color: #0b1114; }
  .contactForm .emailForm {
    width: 100%;
    background-image: -webkit-linear-gradient(320deg, #60b585, #1f323b);
    background-image: -o-linear-gradient(320deg, #60b585, #1f323b);
    background-image: linear-gradient(130deg, #60b585, #1f323b);
    padding: 20px;
    margin-top: 3em; }
    .contactForm .emailForm h3 {
      color: #0b1114; }
    .contactForm .emailForm input, .contactForm .emailForm button, .contactForm .emailForm textarea {
      display: block; }
    .contactForm .emailForm input, .contactForm .emailForm textarea {
      margin-bottom: 20px;
      margin-top: 5px;
      width: 80%;
      border: 1px #FFF solid;
      border-radius: 30px;
      background-color: #FFF;
      color: #1f323b;
      font-size: 1em;
      padding: 10px; }
    .contactForm .emailForm textarea {
      height: 6em;
      font-family: "Montserrat", sans-serif; }
    .contactForm .emailForm label {
      margin-left: 3px; }
    .contactForm .emailForm #emailFormBg {
      width: 50%;
      background-color: #9ce6b3;
      padding: 20px;
      padding-top: 5px;
      text-align: left;
      max-width: 500px; }

#socialMedia ul {
  list-style-type: none;
  margin: 0 auto; }
  #socialMedia ul li {
    display: inline-block;
    width: 40px;
    height: 40px;
    padding: 0 20px; }

/*** 404 page ***/
#error404 {
  text-align: center;
  color: #FFF;
  width: 70%;
  margin: 0 auto; }
  #error404 h2 {
    font-weight: 100; }
  #error404 p {
    text-align: left; }

/*** Modal ***/
.modalWrapper {
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
  text-align: center;
  -webkit-transition: .4s ease-out;
  -o-transition: .4s ease-out;
  transition: .4s ease-out; }
  .modalWrapper #modal {
    position: relative;
    bottom: -10000px;
    width: 90%;
    height: 85%;
    margin: 0 auto;
    background-color: #0b1114;
    -webkit-box-shadow: 20px 20px 20px rgba(11, 17, 20, 0.8);
    box-shadow: 20px 20px 20px rgba(11, 17, 20, 0.8);
    color: #FFF;
    overflow: hidden; }
    .modalWrapper #modal .closeBtn {
      width: 35px;
      height: 35px;
      float: right; }
      .modalWrapper #modal .closeBtn:hover {
        cursor: pointer; }
    .modalWrapper #modal h2 {
      text-align: center;
      color: #FFF;
      text-transform: uppercase;
      font-weight: 100;
      font-size: 1.8em; }
    .modalWrapper #modal .modalHeader {
      padding: 20px; }
    .modalWrapper #modal #modalBody {
      display: inline-block;
      text-align: left;
      font-size: 1.2em;
      width: 100%;
      height: 80%;
      overflow-y: scroll; }
      .modalWrapper #modal #modalBody::-webkit-scrollbar {
        width: 10px; }
      .modalWrapper #modal #modalBody::-webkit-scrollbar-track {
        background: transparent; }
      .modalWrapper #modal #modalBody::-webkit-scrollbar-thumb {
        background: #60b585;
        border: 2px solid #0b1114; }
      .modalWrapper #modal #modalBody #modalSocial {
        padding: 10px;
        width: 90%;
        margin: 0 auto 20px auto; }
        .modalWrapper #modal #modalBody #modalSocial h3 {
          text-align: center;
          color: #FFF;
          text-transform: lowercase;
          font-weight: 100;
          font-size: 1em;
          padding: 20px 0; }
        .modalWrapper #modal #modalBody #modalSocial .socialNavTy {
          text-align: center; }
          .modalWrapper #modal #modalBody #modalSocial .socialNavTy ul li img {
            padding: 0 20px;
            width: 40px;
            height: 40px; }
      .modalWrapper #modal #modalBody #advised {
        font-size: .8em;
        width: 90%;
        margin: 0 auto; }
      .modalWrapper #modal #modalBody #thankYouMsg {
        margin: 0 auto;
        width: 50%;
        padding: 30px 0; }
      .modalWrapper #modal #modalBody ol {
        line-height: 1.5;
        margin: 20px auto 0 auto;
        width: 90%; }
        .modalWrapper #modal #modalBody ol li {
          font-weight: bold;
          font-size: 1.2em;
          margin-top: 15px; }
        .modalWrapper #modal #modalBody ol ul {
          color: #9ce6b3; }
      .modalWrapper #modal #modalBody #faqOk {
        margin: 50px auto; }
      .modalWrapper #modal #modalBody #bookingForm {
        margin: 10px auto;
        width: 90%;
        margin-bottom: -200px; }
        .modalWrapper #modal #modalBody #bookingForm #modalForm {
          width: 500px;
          background-color: #9ce6b3;
          padding: 20px;
          padding-top: 2px;
          text-align: left;
          color: #0b1114; }
          .modalWrapper #modal #modalBody #bookingForm #modalForm #dateSelect {
            font-weight: bold;
            color: #02424e;
            font-size: .8em;
            display: block; }
          .modalWrapper #modal #modalBody #bookingForm #modalForm button {
            margin: 0; }
      .modalWrapper #modal #modalBody button {
        display: block;
        margin: 0 auto; }

/**** media query start ****/
@media only screen and (max-width: 1350px) and (min-width: 825px) {
  /*** contact, booking ***/
  /** page headers **/
  .pageOval {
    height: 500px;
    top: -230px; }
  /*** whole site styles ***/
  header {
    width: 100%; }
  main {
    margin-left: 0;
    margin-right: 0; }
  footer {
    width: 100%; }
    footer .footerOval {
      height: 700px;
      bottom: -450px; }
  .socialNavFt ul li img {
    width: 30px;
    height: 30px; }
  /**** home page ****/
  #hero {
    margin-top: 300px;
    margin-left: 90px; }
  .heroOval {
    height: 850px;
    top: -140px;
    right: -40%; }
  #photos {
    margin-top: 190px; }
    #photos h3 {
      margin-left: 90px; }
    #photos #bgPlant {
      top: 450px;
      left: -150px;
      width: 600px; }
    #photos .pihBottom p {
      margin-right: 90px; }
    #photos #bgPlantBottom {
      right: -180px; }
  /*** donations ***/
  .donations #donationInfo {
    margin-right: 40px; }
  /*** About Page ***/
  #aboutMe {
    max-width: 620px;
    margin-right: 90px; }
  .cta button {
    margin-left: 40px; }
  .cta .bgcta {
    width: 110%;
    min-width: 1100px; }
  /*** Contact page ***/
  .contactForm {
    width: 100%; }
    .contactForm #contactReason button {
      margin-right: 80px;
      width: 170px; }
  /*** Booking page ***/
  #bookingInfo {
    margin-right: 90px;
    margin-left: 90px; }
  #calendar #cal {
    width: 100%; }
    #calendar #cal td {
      height: 5em; }
  /*** modal ***/
  .modalWrapper #modal {
    margin: 0 auto; } }

/*** mobile styles ***/
@media only screen and (max-width: 825px) and (min-width: 500px) {
  /*** contact, booking ***/
  /** page headers **/
  .pageOval {
    height: 500px;
    top: -230px; }
  /*** whole site styles ***/
  header {
    width: 100%; }
  .mobileNavWrap {
    position: absolute;
    right: -500px;
    top: -500px;
    width: 100%;
    -webkit-transition: .4s ease-out;
    -o-transition: .4s ease-out;
    transition: .4s ease-out; }
  .mobileNavBtns {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    width: 40px;
    z-index: 1; }
    .mobileNavBtns:hover {
      cursor: pointer; }
    .mobileNavBtns #openMenu {
      display: block; }
    .mobileNavBtns #closeMenu {
      display: none; }
    .mobileNavBtns img {
      position: absolute;
      top: 5px;
      background-color: transparent;
      width: 35px;
      height: 35px;
      -webkit-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      transform: rotate(-180deg); }
  nav {
    position: absolute;
    right: -370px;
    top: 0; }
    nav ul li {
      display: block;
      padding: 30px 0;
      font-size: 1.5em; }
    nav .navOval {
      background-color: #0b1114;
      border-radius: 50%;
      position: relative;
      z-index: -2;
      top: -450px;
      left: -200px;
      height: 700px;
      width: 650px;
      -webkit-transform: rotate(10deg);
      -ms-transform: rotate(10deg);
      transform: rotate(10deg); }
  main {
    margin-left: 0;
    margin-right: 0; }
  footer {
    width: 100%; }
    footer .footerOval {
      height: 700px;
      bottom: -500px; }
    footer small {
      font-size: 2em; }
    footer img {
      width: 28px;
      height: 28px; }
    footer span {
      font-size: 1.5em; }
  .socialNavTop {
    position: absolute;
    top: 450px;
    margin-right: 50px; }
    .socialNavTop ul li img {
      width: 40px;
      height: 40px;
      padding: 0 30px; }
  .socialNavFt ul li img {
    padding: 0 30px;
    width: 40px;
    height: 40px; }
  /**** home page ****/
  #hero {
    margin-left: 30px;
    margin-top: 35%;
    margin-top: 270px; }
    #hero #heroWrapper h2 {
      text-align: right;
      font-size: 3.5em; }
      #hero #heroWrapper h2 span {
        display: block; }
    #hero #heroWrapper h3 {
      font-size: 1.5em; }
    #hero img {
      width: 190px;
      height: 190px; }
  .heroOval {
    height: 850px;
    top: -140px;
    right: -40%; }
  #photos {
    margin-top: 175px; }
    #photos h3 {
      margin-left: 120px; }
    #photos #bgPlant {
      top: 400px;
      left: -150px; }
    #photos .pihBottom p {
      margin-right: 90px; }
    #photos #bgPlantBottom {
      right: -180px; }
  /*** donations ***/
  .donations {
    text-align: center; }
    .donations #donationInfo {
      max-width: 60%;
      margin-right: 0;
      display: inline-block; }
  /*** About Page ***/
  #aboutMe {
    max-width: 80%;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    float: none;
    margin-top: 17em; }
  .aboutOval {
    top: -60px;
    height: 43%;
    width: 1600px;
    right: 0;
    left: 0;
    margin-right: 50%;
    margin-left: -400px; }
  #specialties ul {
    padding: 0; }
    #specialties ul li {
      font-size: 1em;
      margin: 0 auto; }
      #specialties ul li:first-child {
        padding-right: 8%; }
      #specialties ul li:last-child {
        padding-left: 8%; }
  .cta p {
    width: 70%;
    display: block; }
  .cta button {
    text-decoration: none;
    margin: 0; }
  .cta .bgcta {
    width: 110%;
    min-width: 1100px;
    left: -390px; }
  /*** Booking page ***/
  #bookingInfo {
    margin-right: 90px;
    margin-left: 90px; }
  #calendar #cal {
    width: 100%; }
    #calendar #cal caption {
      font-size: 1.8em; }
    #calendar #cal td {
      height: 5em;
      overflow: hidden; }
      #calendar #cal td p:nth-child(1) {
        font-size: 0;
        visibility: hidden; }
  /*** Contact page ***/
  .contactForm {
    width: 100%; }
    .contactForm #contactReason {
      width: 100%;
      margin: 0;
      text-align: center; }
      .contactForm #contactReason h3 {
        text-align: left;
        margin-left: 15%; }
      .contactForm #contactReason button {
        display: inline-block;
        width: 150px;
        margin-right: 10px;
        padding-left: 0;
        padding-right: 0;
        text-align: center; }
    .contactForm .emailForm {
      padding-left: 0;
      padding-right: 0;
      padding-top: 150px; }
      .contactForm .emailForm #emailFormBg {
        margin: 0 auto;
        width: 80%; }
  /*** modal ***/
  .modalWrapper #modal {
    height: 85%;
    margin-top: -25px; }
    .modalWrapper #modal #modalBody {
      width: 100%; }
      .modalWrapper #modal #modalBody #thankYouMsg {
        width: 80%; }
      .modalWrapper #modal #modalBody #modalSocial h3 {
        padding: 10px 0; }
      .modalWrapper #modal #modalBody #bookingForm {
        padding-top: 80px;
        width: 99%; }
        .modalWrapper #modal #modalBody #bookingForm #modalForm {
          margin: auto;
          width: 80%; }
          .modalWrapper #modal #modalBody #bookingForm #modalForm button {
            margin: 0; }
      .modalWrapper #modal #modalBody button {
        margin: 20% auto 0 auto; } }

/*** mobile styles smaller***/
@media only screen and (max-width: 500px) {
  /*** contact, booking ***/
  /** page headers **/
  .pageOval {
    height: 500px;
    top: -250px;
    right: 50%;
    width: 800px;
    margin-right: -400px; }
  /*** whole site styles ***/
  header {
    width: 100%; }
  .mobileNavWrap {
    position: absolute;
    right: -500px;
    top: -500px;
    width: 100%;
    -webkit-transition: .4s ease-out;
    -o-transition: .4s ease-out;
    transition: .4s ease-out; }
  .mobileNavBtns {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    width: 40px;
    z-index: 1; }
    .mobileNavBtns:hover {
      cursor: pointer; }
    .mobileNavBtns img {
      position: absolute;
      top: 5px;
      background-color: transparent;
      width: 35px;
      height: 35px;
      -webkit-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      transform: rotate(-180deg); }
  nav {
    position: absolute;
    right: -450px;
    top: 0; }
    nav ul li {
      display: block;
      padding: 30px 0;
      font-size: 1.5em; }
    nav .navOval {
      background-color: #0b1114;
      border-radius: 50%;
      position: relative;
      z-index: -2;
      top: -450px;
      left: -150px;
      height: 700px;
      width: 650px;
      -webkit-transform: rotate(10deg);
      -ms-transform: rotate(10deg);
      transform: rotate(10deg); }
  main {
    margin-left: 0;
    margin-right: 0; }
  footer {
    width: 100%; }
    footer .footerOval {
      height: 250px;
      bottom: -100px; }
    footer small {
      font-size: 2em; }
    footer img {
      width: 28px;
      height: 28px; }
    footer span {
      font-size: 1.5em; }
  .socialNavTop {
    position: absolute;
    top: 450px;
    margin-right: 0; }
    .socialNavTop ul li img {
      width: 40px;
      height: 40px;
      padding: 0 30px; }
  .socialNavFt ul li img {
    padding: 0 30px;
    width: 40px;
    height: 40px; }
  /**** home page ****/
  #hero {
    margin-left: 10px;
    margin-top: 320px; }
    #hero #heroWrapper h2 {
      text-align: right;
      font-size: 2.5em; }
      #hero #heroWrapper h2 span {
        display: block; }
    #hero #heroWrapper h3 {
      font-size: 1.1em; }
    #hero img {
      width: 140px;
      height: 140px; }
  .heroOval {
    height: 850px;
    width: 1200px;
    top: -140px;
    right: -500px; }
  #photos {
    margin-top: 175px; }
    #photos h3 {
      margin-left: 90px; }
    #photos #bgPlant {
      top: 550px;
      left: -50px;
      width: 430px; }
    #photos .pihBottom p {
      margin-right: 10px;
      font-size: .9em; }
    #photos .pihBottom a img {
      width: 40px;
      height: 40px; }
    #photos #bgPlantBottom {
      right: -50px;
      top: -340px;
      width: 580px;
      margin-bottom: 4em; }
  /*** donations ***/
  .donations {
    text-align: center;
    padding: 30px 0;
    margin: 5em auto 0 auto; }
    .donations #donationInfo {
      max-width: 80%;
      display: inline-block;
      padding: 5px 5px 20px 5px; }
  /*** About Page ***/
  #aboutMe {
    max-width: 80%;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    float: none;
    margin-top: 17em; }
    #aboutMe p {
      line-height: 1.5; }
  .aboutOval {
    top: -60px;
    height: 43%;
    width: 1600px;
    right: 0;
    left: 0;
    margin-right: 50%;
    margin-left: -600px; }
  #specialties ul {
    padding: 0; }
    #specialties ul li {
      display: block;
      font-size: 1em;
      margin: 5em auto; }
      #specialties ul li:first-child {
        padding: 0; }
      #specialties ul li:last-child {
        padding: 0; }
  .cta {
    margin-bottom: -15em !important;
    margin-top: 0 !important; }
    .cta p {
      width: 80%;
      display: block; }
    .cta button {
      margin: 0; }
    .cta .bgcta {
      width: 250%;
      min-width: 1200px;
      left: -600px;
      top: -190px; }
  /*** Contact page ***/
  .contactForm {
    width: 100%;
    margin-bottom: 8em; }
    .contactForm #contactReason {
      margin: 0;
      text-align: center; }
      .contactForm #contactReason h3 {
        margin-left: 10px;
        text-align: left; }
      .contactForm #contactReason button {
        width: 100px;
        padding-left: 0;
        padding-right: 0;
        font-size: 10pt;
        margin: 0 auto; }
        .contactForm #contactReason button:nth-child(3) {
          margin-left: 10px;
          margin-right: 10px; }
    .contactForm .emailForm {
      padding-left: 0;
      padding-right: 0;
      padding-top: 150px; }
      .contactForm .emailForm #emailFormBg {
        margin: 0 auto;
        width: 80%; }
  #socialMedia {
    margin-bottom: 0; }
  /*** Booking page ***/
  #bookingInfo {
    margin-left: 10px;
    margin-right: 10px; }
    #bookingInfo h3, #bookingInfo h4 {
      line-height: normal; }
  #calendar {
    margin-bottom: 3em; }
    #calendar #cal {
      width: 100%; }
      #calendar #cal #arrowWrap #prev {
        margin-left: 10px;
        width: 60px;
        height: 60px; }
        #calendar #cal #arrowWrap #prev i {
          margin: 0; }
      #calendar #cal #arrowWrap #next {
        margin-right: 10px;
        width: 60px;
        height: 60px; }
        #calendar #cal #arrowWrap #next i {
          margin: 0; }
      #calendar #cal caption {
        font-size: 1.5em; }
      #calendar #cal th {
        font-size: 1em; }
      #calendar #cal td {
        height: 8em;
        font-size: 1em;
        padding: 2px;
        height: 3em;
        overflow: hidden; }
        #calendar #cal td p:nth-child(1) {
          font-size: 0;
          visibility: hidden; }
  #contactForQuestions {
    margin-bottom: 0; }
  /*** modal ***/
  .modalWrapper #modal {
    height: 87%;
    width: 90%;
    margin: -16px auto; }
    .modalWrapper #modal .closeBtn {
      width: 25px;
      height: 25px; }
    .modalWrapper #modal h2 {
      font-size: 1.5em; }
    .modalWrapper #modal .modalHeader {
      padding: 10px 10px 0px 10px; }
    .modalWrapper #modal #modalBody {
      width: 100%;
      height: 90%;
      overflow-x: hidden; }
      .modalWrapper #modal #modalBody #thankYouMsg {
        margin: 0 auto;
        width: 80%;
        padding: 30px 0; }
      .modalWrapper #modal #modalBody #bookingForm {
        padding-top: 120px;
        width: 100%; }
        .modalWrapper #modal #modalBody #bookingForm #modalForm {
          margin: auto;
          width: 85%; }
          .modalWrapper #modal #modalBody #bookingForm #modalForm button {
            margin: 0; }
      .modalWrapper #modal #modalBody button {
        margin: 10px auto 0 auto; }
  /*** 404 page ***/
  #error404 {
    width: 90%; } }

@media only screen and (max-height: 570px) {
  /*** modal ***/
  .modalWrapper #modal {
    height: 90%; }
    .modalWrapper #modal #modalBody {
      height: 85%; }
      .modalWrapper #modal #modalBody #thankYouMsg {
        margin: 0 auto;
        width: 90%;
        padding: 10% 0; }
      .modalWrapper #modal #modalBody #modalSocial h3 {
        padding: 10px 0; }
      .modalWrapper #modal #modalBody #modalSocial .socialNavTy {
        text-align: center; }
        .modalWrapper #modal #modalBody #modalSocial .socialNavTy ul li img {
          padding: 0 20px;
          width: 40px;
          height: 40px; }
      .modalWrapper #modal #modalBody button {
        margin: -20px auto 0 auto; } }
